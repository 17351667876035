import { MailSettingTest } from 'modules/administration/models/mail-setting-test';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {MailSettingTest}
     */
    model() {
        let model = new MailSettingTest();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        this.addressee = {
            type:  'text',
            key:   'addressee',
            id:    'addressee',
            label: 'form.field.addressee',
            size:  12,
        };

        this.message = {
            type:  'textarea',
            key:   'message',
            id:    'message',
            label: 'form.field.message',
            size:  12,
        };

        return [
            [this.addressee],
            [this.message],
        ];
    }

}
