import { BaseModel } from 'modules/core/models/base-model';

const PENDING  = 1;
const ACTIVE   = 2;
const INACTIVE = 3;

export class UserStatus extends BaseModel {
    static get PENDING() {
        return PENDING;
    }

    static get ACTIVE() {
        return ACTIVE;
    }

    static get INACTIVE() {
        return INACTIVE;
    }
}
