import { BaseModel } from 'modules/core/models/base-model';

const GLOBAL_ADMINISTRATOR              = 1;
const MANAGING_ENTITY_ADMINISTRATOR     = 2;
const CONTROLLING_ENTITY_ADMINISTRATOR  = 3;
const INSPECTING_ENTITY_ADMINISTRATOR   = 4;
const MAINTENANCE_COMPANY_ADMINISTRATOR = 5;
const INSTALLING_COMPANY_ADMINISTRATOR  = 6;
const OWNER                             = 7;
const CONTROLLING_AGENT                 = 8;
const INSPECTOR                         = 9;
const MAINTENANCE_TECHNICIAN            = 10;
const INSTALLING_TECHNICIAN             = 11;

export class Role extends BaseModel {

    name            = null;
    slug            = null;
    description     = null;
    entity_type_id  = null;
    system_reserved = null;
    status_id       = null;
    permissions     = [];

    static get GLOBAL_ADMINISTRATOR() {
        return GLOBAL_ADMINISTRATOR;
    }

    static get MANAGING_ENTITY_ADMINISTRATOR() {
        return MANAGING_ENTITY_ADMINISTRATOR;
    }

    static get CONTROLLING_ENTITY_ADMINISTRATOR() {
        return CONTROLLING_ENTITY_ADMINISTRATOR;
    }

    static get INSPECTING_ENTITY_ADMINISTRATOR() {
        return INSPECTING_ENTITY_ADMINISTRATOR;
    }

    static get MAINTENANCE_COMPANY_ADMINISTRATOR() {
        return MAINTENANCE_COMPANY_ADMINISTRATOR;
    }

    static get INSTALLING_COMPANY_ADMINISTRATOR() {
        return INSTALLING_COMPANY_ADMINISTRATOR;
    }

    static get OWNER() {
        return OWNER;
    }

    static get CONTROLLING_AGENT() {
        return CONTROLLING_AGENT;
    }

    static get INSPECTOR() {
        return INSPECTOR;
    }

    static get MAINTENANCE_TECHNICIAN() {
        return MAINTENANCE_TECHNICIAN;
    }

    static get INSTALLING_TECHNICIAN() {
        return INSTALLING_TECHNICIAN;
    }

}
