import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ParishesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/locations/parishes/all');
    }

    /**
     * Returns all records, by the given counties
     *
     * @param counties
     *
     * @returns {*}
     */
    allByCounties(counties = []) {
        return this.httpClient.post('administration/locations/parishes/all', {counties});
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('administration/locations/parishes/active');
    }

    /**
     * Returns active records, by the given county
     *
     * @param id
     *
     * @returns {*}
     */
    activeByCounty(id) {
        return this.httpClient.get('administration/locations/parishes/active/' + id);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/locations/parishes/search', criteria);
    }

}
