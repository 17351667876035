import { inject }            from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { UsersRepository }   from 'modules/administration/users/services/repository';
import { FormSchema }        from 'modules/administration/users/view/form-schema';

@inject(AppContainer, UsersRepository, FormSchema)
export class EditUser extends BaseFormViewModel {

    formId = 'administration.users.view-form';

    alert       = {};
    model       = {};
    schema      = {};
    permissions = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultFormView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.manage',
            'administration.users.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return Promise.all([
            this.repository.find(id).then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);
            }),
        ]);
    }
}
