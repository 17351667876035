import { BaseModel } from 'modules/core/models/base-model';

const AWAITING_PAYMENT = 1;
const PAID             = 2;
const CANCELED         = 3;

export class PaymentStatus extends BaseModel {

    static get AWAITING_PAYMENT() {
        return AWAITING_PAYMENT;
    }

    static get PAID() {
        return PAID;
    }

    static get CANCELED() {
        return CANCELED;
    }

}
