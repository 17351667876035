import { File } from 'modules/administration/models/file';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {File}
     */
    model() {
        let model = new File();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        this.file_id = {
            type:  'file-dropzone',
            key:   'file_id',
            id:    'file_id' + viewModel.formId,
            label: 'form.field.file',
            size:  12,
            url:   viewModel.relatableRepository.uploadUri(),
        };

        this.display_name = {
            type:  'text',
            key:   'display_name',
            id:    'file_name' + viewModel.formId,
            label: 'form.field.name',
            size:  12,
        };

        this.description = {
            type:     'textarea',
            key:      'description',
            id:       'file_description',
            label:    'form.field.description',
            size:     12,
            required: false,
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     (event) => viewModel.creationSchemaVisible = false,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal pull-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            attributes: {
                class: 'form-wizard-actions ml-auto ml-2 mr-2',
            },
            actions: [
                this.closeButton,
                this.submitButton,
            ],
        };

        return [
            [this.file_id],
            [this.display_name],
            [this.description],
            [this.buttons],
        ];
    }

}
