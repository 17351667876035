import { BooleanStatus } from 'modules/administration/models/boolean-status';
import { Role }          from 'modules/administration/models/role';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{status_id: number}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {Role}
     */
    model() {
        let model = new Role();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.slug = {
            type:       'text',
            key:        'slug',
            label:      'form.field.slug',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:       'boolean-statuses-select',
            key:        'status_id',
            label:      'form.field.status',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.description = {
            type:       'textarea',
            key:        'description',
            label:      'form.field.description',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('administration.roles.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.name, this.slug, this.status_id],
            [this.description],
            [this.buttons],
        ];
    }

}
