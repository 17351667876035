import { inject, PLATFORM }       from 'aurelia-framework';
import { BaseFormViewModel }      from 'base-form-view-model';
import { FormSchema }             from 'modules/administration/translations/edit/form-schema';
import { TranslationsRepository } from 'modules/administration/translations/services/repository';
import { AppContainer }           from 'resources/services/app-container';

@inject(AppContainer, TranslationsRepository, FormSchema)
export class EditTranslation extends BaseFormViewModel {

    headerTitle    = 'form.title.edit-record';
    newRecordRoute = 'administration.translations.create';
    formId         = 'administration.translations.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    languages = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        if (this.appContainer.authenticatedUser.isSystemReserved()) {
            this.newRecordRoute = null;
        }

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can(['administration.translations.manage', 'administration.translations.edit']);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema = this.formSchema.schema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then(response => {
                if (response.status === true) {
                    this.initialModel.assign(this.model);

                    this.redirectToRoute('administration.translations.index', 500);
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch(error => console.log(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return Promise.all([
            this.repository.find(params.id).then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);
            }),
            this.repository.allLocales().then((response) => {
                this.languages = response;
            }),
        ]);
    }

}
