import { inject, transient }   from 'aurelia-framework';
import { DistrictsRepository } from 'modules/administration/locations/districts/services/repository';
import { CountiesRepository }  from 'modules/administration/locations/counties/services/repository';
import { ParishesRepository }  from 'modules/administration/locations/parishes/services/repository';

@transient()
@inject(DistrictsRepository, CountiesRepository, ParishesRepository)
export class SingleFormSchema {

    settings = {
        district: {
            key:   'district_id',
            label: 'form.field.district',
            size:  4,
        },
        county:   {
            key:   'county_id',
            label: 'form.field.county',
            size:  4,
        },
        parish:   {
            key:   'parish_id',
            label: 'form.field.parish',
            size:  4,
        },
    };

    /**
     * Constructor
     *
     * @param districtsRepository
     * @param countiesRepository
     * @param parishesRepository
     */
    constructor(districtsRepository, countiesRepository, parishesRepository) {
        this.districtsRepository = districtsRepository;
        this.countiesRepository  = countiesRepository;
        this.parishesRepository  = parishesRepository;
    }

    withSettings(settings) {
        this.settings = $.extend(true, this.settings, settings);

        return this;
    }

    /**
     * Returns the data form schema
     *
     * @param model
     * @param required
     * @param disabled
     *
     * @returns {*[]}
     */
    schema(model, required = true, disabled = false) {
        const processLocationResults = (row) => {
            row.name = `${row.code} - ${row.name}`;

            return row;
        };

        this.district_id = {
            type:           'select2',
            key:            this.settings.district.key,
            id:             this.settings.district.id,
            label:          this.settings.district.label,
            size:           this.settings.district.size,
            required:       required,
            remoteSource:   this.districtsRepository.active.bind(this.districtsRepository),
            processResults: (row) => processLocationResults(row),
            observers:      [
                (newValue) => this.handleDistrictChanged(newValue),
            ],
            attributes:     {
                disabled: disabled,
            },
        };

        this.county_id = {
            type:                   'select2',
            key:                    this.settings.county.key,
            id:                     this.settings.county.id,
            label:                  this.settings.county.label,
            size:                   this.settings.county.size,
            required:               required,
            remoteSource:           this.countiesRepository.activeByDistrict.bind(this.countiesRepository),
            remoteSourceParameters: () => model[this.settings.district.key],
            processResults:         (row) => processLocationResults(row),
            observers:              [
                (newValue) => this.handleCountyChanged(newValue),
            ],
            attributes:             {
                disabled: disabled || !model[this.settings.district.key],
            },
        };

        this.parish_id = {
            type:                   'select2',
            key:                    this.settings.parish.key,
            id:                     this.settings.parish.id,
            label:                  this.settings.parish.label,
            size:                   this.settings.parish.size,
            required:               required,
            remoteSource:           this.parishesRepository.activeByCounty.bind(this.parishesRepository),
            remoteSourceParameters: () => model[this.settings.county.key],
            processResults:         (row) => processLocationResults(row),
            attributes:             {
                disabled: disabled || !model[this.settings.county.key],
            },
        };

        return [
            [this.district_id, this.county_id, this.parish_id],
        ];
    }

    /**
     * Handles the `district` field changes
     *
     * @param newValue
     */
    handleDistrictChanged(newValue) {
        this.county_id.instance.disable();

        if (newValue) {
            this.county_id.instance.fetchData().then(() => this.county_id.instance.enable());
        }
    }

    /**
     * Handles the `county` field changes
     *
     * @param newValue
     */
    handleCountyChanged(newValue) {
        this.parish_id.instance.disable();

        if (newValue) {
            this.parish_id.instance.fetchData().then(() => this.parish_id.instance.enable());
        }
    }

}
