import { BaseModel } from 'modules/core/models/base-model';

const MULTIBANCO = 1;
const CHECK      = 2;
const CASH       = 3;

export class PaymentType extends BaseModel {

    static get MULTIBANCO() {
        return MULTIBANCO;
    }

    static get CHECK() {
        return CHECK;
    }

    static get CASH() {
        return CASH;
    }

}
