import { bindable, inject }    from 'aurelia-framework';
import { AppContainer }        from 'resources/services/app-container';
import { BaseViewModel }       from 'base-view-model';
import { DistrictsRepository } from 'modules/administration/locations/districts/services/repository';

@inject(AppContainer, DistrictsRepository)
export class DistrictsListing extends BaseViewModel {

    listingId = 'administration-locations-districts-listing';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns: [
                {
                    data:  'code',
                    name:  'districts.code',
                    title: 'form.field.code',
                },
                {
                    data:  'name',
                    name:  'districts.name',
                    title: 'form.field.name',
                },
            ],
        };
    }
}
