import { BaseModel } from 'modules/core/models/base-model';

export class Message extends BaseModel {

    subject      = null;
    body         = null;
    addresser_id = null;
    allows_reply = null;
    addressees   = [];
}
