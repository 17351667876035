import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class CountiesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/locations/counties/all');
    }

    /**
     * Returns all records, by the given districts
     *
     * @param districts
     *
     * @returns {*}
     */
    allByDistricts(districts = []) {
        return this.httpClient.post('administration/locations/counties/all', {districts});
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('administration/locations/counties/active');
    }

    /**
     * Returns active records, by the given district
     *
     * @param id
     *
     * @returns {*}
     */
    activeByDistrict(id) {
        return this.httpClient.get('administration/locations/counties/active/' + id);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/locations/counties/search', criteria);
    }

}
