import { bindable, inject }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { FilesRepository }                 from './services/repository';
import { Downloader }                      from 'resources/services/downloader';
import { DialogController, DialogService } from 'aurelia-dialog';

@inject(AppContainer, FilesRepository, Downloader, DialogController, DialogService)
export class ListFilesModal {

    @bindable settings = {
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param downloader
     * @param dialogController
     * @param dialogService
     */
    constructor(appContainer, repository, downloader, dialogController, dialogService) {
        this.dialogController = dialogController;
    }

    /**
     * Handles attached event
     */
    attached() {

        this.settings.title = this.appContainer.i18n.tr('form.title.identified-files-listing', {
            description: this.relatableModel[this.relatableModelNameField],
        });

    }

}
