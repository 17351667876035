import { bindable, inject }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseListViewModel } from 'base-list-view-model';

@inject(AppContainer)
export class ListLocations extends BaseListViewModel {

    listingId = 'administration-locations-listing';

    @bindable headerTitle    = 'listing.administration.locations';
    @bindable newRecordRoute = 'administration.locations.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can(['administration.locations.view']);
    }

}
