import '@babel/polyfill';

import { AuthService }         from 'aurelia-auth';
import { PLATFORM }            from 'aurelia-pal';
import { AureliaAuthConfig }   from 'resources/configs/aurelia-auth-config';
import { AureliaI18NConfig }   from 'resources/configs/aurelia-i18n-config';
import { AureliaDialogConfig } from 'resources/configs/aurelia-dialog-config';
import { GoogleMapsConfig }    from 'resources/configs/google-maps-config';
import environment             from 'environment';
import * as Bluebird           from 'bluebird';

import 'assets/css/icons/icomoon/styles.min.css';
import 'assets/css/bootstrap.min.css';
import 'assets/css/bootstrap_limitless.min.css';
import 'assets/css/layout.min.css';
import 'assets/css/components.min.css';
import 'assets/css/colors.min.css';
import 'assets/css/material-design-spinner.min.css';
import 'assets/css/custom.min.css';
import 'resources/support/utilities';

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({warnings: {wForgottenReturn: false}});

export function configure(aurelia) {
    aurelia.use
        .standardConfiguration()
        .developmentLogging(environment.debug ? 'debug' : 'warn')
        .plugin(PLATFORM.moduleName('jquery'))
        .plugin(PLATFORM.moduleName('bootstrap'))
        .plugin(PLATFORM.moduleName('aurelia-animator-css'))
        .plugin(PLATFORM.moduleName('aurelia-auth'), config => config.configure(AureliaAuthConfig))
        .plugin(PLATFORM.moduleName('aurelia-i18n'), config => AureliaI18NConfig.configure(aurelia, config))
        .plugin(PLATFORM.moduleName('aurelia-dialog'), config => AureliaDialogConfig.configure(config))
        .plugin(PLATFORM.moduleName('aurelia-google-maps'), config => config.options(GoogleMapsConfig))
        .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
        .feature(PLATFORM.moduleName('resources/attributes/index'))
        .feature(PLATFORM.moduleName('resources/elements/aurelia-form/index'))
        .feature(PLATFORM.moduleName('resources/elements/form-elements/index'))
        .feature(PLATFORM.moduleName('resources/elements/html-elements/index'))
        .feature(PLATFORM.moduleName('resources/value-converters/index'))
        .preTask(registerAdditionalPlugins)
        .preTask(registerIntendedUrl);

    aurelia.start().then(instance => aurelia.setRoot(applicableRoot(instance)));
}

/**
 * Registers additional plugins
 *
 * @param frameworkConfiguration
 *
 * @returns {*}
 */
function registerAdditionalPlugins(frameworkConfiguration) {
    if (environment.testing) {
        frameworkConfiguration.aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    return frameworkConfiguration;
}

/**
 * Registers intended url
 *
 * @param frameworkConfiguration
 *
 * @returns {*}
 */
function registerIntendedUrl(frameworkConfiguration) {
    let auth = frameworkConfiguration.container.get(AuthService);

    if (!auth.isAuthenticated()) {
        auth.auth.setInitialUrl(window.location.href);
    }

    return frameworkConfiguration;
}

/**
 * Returns applicable root
 *
 * @param frameworkConfiguration
 *
 * @returns {string}
 */
function applicableRoot(frameworkConfiguration) {
    let auth = frameworkConfiguration.container.get(AuthService);

    if (environment.maintenance === true) {
        return PLATFORM.moduleName('maintenance');
    }

    return auth.isAuthenticated() ? PLATFORM.moduleName('app') : PLATFORM.moduleName('app-offline');
}
