import { BaseModel } from 'modules/core/models/base-model';

export class Payment extends BaseModel {

    type_id   = null;
    entity    = null;
    reference = null;
    value     = null;
    deadline  = null;
    lines     = [];

}
