import { bindable, inject }   from 'aurelia-framework';
import { AppContainer }       from 'resources/services/app-container';
import { BaseViewModel }      from 'base-view-model';
import { CountiesRepository } from 'modules/administration/locations/counties/services/repository';

@inject(AppContainer, CountiesRepository)
export class CountiesListing extends BaseViewModel {

    listingId = 'administration-locations-counties-listing';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns: [
                {
                    data:  'code',
                    name:  'counties.code',
                    title: 'form.field.code',
                },
                {
                    data:  'name',
                    name:  'counties.name',
                    title: 'form.field.name',
                },
                {
                    data:  'district_name',
                    name:  'districts.name',
                    title: 'form.field.district',
                },
            ],
        };
    }
}
