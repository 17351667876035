import { bindable, customElement } from 'aurelia-framework';
import collect from 'collect.js';

@customElement('timeline')
export class Timeline {

    listingId = 'timeline-' + Math.floor(Math.random() * 65000);

    @bindable items;
    @bindable dateFormat = 'YYYY-MM-DD HH:mm';
    @bindable icon = 'icon-folder-search';

    /**
     * Handles activate event
     */
    bind() {
        let sorted = collect(this.items).sortByDesc('date').all();

        this.items.splice(0, this.items.length, ...sorted);
    }

}
