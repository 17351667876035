import { Aurelia, inject }          from 'aurelia-framework';
import { AppContainer }             from 'resources/services/app-container';
import { AuthService, FetchConfig } from 'aurelia-auth';
import { BaseViewModel }            from 'base-view-model';
import environment                  from 'environment';

@inject(Aurelia, AppContainer, AuthService, FetchConfig)
export class Maintenance extends BaseViewModel {

    /**
     * Constructor
     *
     * @param aurelia
     * @param authService
     * @param appContainer
     * @param fetchConfig
     */
    constructor(aurelia, appContainer, authService, fetchConfig) {
        super(appContainer);

        this.aurelia     = aurelia;
        this.auth        = authService;
        this.fetchConfig = fetchConfig;
        this.environment = environment;
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        super.activate(params);

        this.fetchConfig.configure();

        // configures aurelia-auth fetch client to include application locale
        this.fetchConfig.httpClient.configure(config => {
            config.withDefaults({
                headers: {
                    'Application-Locale': this.appContainer.i18n.getLocale(),
                },
            });
        });
    }

    /**
     * Runs after view is attached
     */
    attached() {
        document.body.className = 'login-container';
    }

    /**
     * Reloads page
     */
    reload() {
        window.location.reload(true);
    }

}
