import { inject }              from 'aurelia-framework';
import { CustomHttpClient }    from 'resources/services/custom-http-client';
import { LanguagesRepository } from 'modules/administration/languages/services/repository';

@inject(CustomHttpClient, LanguagesRepository)
export class TranslationsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param languagesRepository
     */
    constructor(httpClient, languagesRepository) {
        this.httpClient = httpClient;

        this.languagesRepository = languagesRepository;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/translations/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('administration/translations/active');
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/translations/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('administration/translations', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get('administration/translations/' + id);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put('administration/translations/' + id, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete('administration/translations/' + id + '/destroy');
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('administration/translations/destroy-selected', {ids: ids});
    }

    /**
     * Returns all languages
     */
    allLocales() {
        return this.languagesRepository.allLocales();
    }
}
