import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class JasminSettingsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Tests the given settings
     *
     * @param data
     *
     * @returns {*}
     */
    test(data) {
        return this.httpClient.post('administration/jasmin-settings/test', data);
    }

}
