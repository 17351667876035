import { Translation } from 'modules/administration/models/translation';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {Translation}
     */
    model() {
        let model = new Translation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        let isSystemReserved = viewModel.appContainer.authenticatedUser.isSystemReserved();

        this.group = {
            type:       'text',
            key:        'group',
            label:      'form.field.group',
            size:       4,
            attributes: {
                disabled: !isSystemReserved,
            },
        };

        this.key = {
            type:       'text',
            key:        'key',
            label:      'form.field.key',
            size:       8,
            attributes: {
                disabled: !isSystemReserved,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('administration.translations.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     (event) => {
                if (viewModel.model.owned_by_client && viewModel.appContainer.authenticatedUser.isSystemReserved()) {
                    return this.showSubmitConfirmationAlert(viewModel);
                }

                return viewModel.submit();
            },
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        let schema = [
            [this.group, this.key],
        ];

        viewModel.languages.forEach((language) => {
            schema.push([{
                type:  'textarea',
                key:   'text_' + language.locale,
                label: language.name,
                size:  12,
            }]);
        });

        schema.push([this.buttons]);

        return schema;
    }

    /**
     * Shows submit confirmation alert
     *
     * @param viewModel
     */
    showSubmitConfirmationAlert(viewModel) {
        let i18n        = viewModel.appContainer.i18n;
        let swalService = viewModel.appContainer.swalService;

        let options = {
            title:              i18n.tr('text.attention'),
            text:               i18n.tr('form.message.want-override-client-translation'),
            type:               'warning',
            showCancelButton:   true,
            cancelButtonText:   i18n.tr('form.button.cancel'),
            confirmButtonColor: '#FF7043',
            confirmButtonText:  i18n.tr('form.button.ok'),
            closeOnConfirm:     true,
        };

        swalService.customAlert(options, (confirmed) => {
            if (confirmed) {
                viewModel.submit();
            }
        });
    }

}
