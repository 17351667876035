import { inject }                 from 'aurelia-framework';
import { User }                   from 'modules/administration/models/user';
import { UserStatus }             from 'modules/administration/models/user-status';
import { RolesRepository }        from 'modules/administration/roles/services/repository';
import { UserStatusesRepository } from 'modules/administration/user-statuses/services/repository';

@inject(RolesRepository, UserStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: UserStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param rolesRepository
     * @param userStatusesRepository
     */
    constructor(rolesRepository, userStatusesRepository) {
        this.rolesRepository        = rolesRepository;
        this.userStatusesRepository = userStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {User}
     */
    model() {
        let model = new User();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.name',
            size:  8,
        };

        this.email = {
            type:  'email',
            key:   'email',
            label: 'form.field.email',
            size:  4,
        };

        this.password = {
            type:  'password',
            label: 'form.field.password',
            size:  4,
            key:   'password',
        };

        this.password_confirmation = {
            type:  'password',
            key:   'password_confirmation',
            label: 'form.field.password-confirmation',
            size:  4,
        };

        this.role_id = {
            type:         'select2',
            key:          'role_id',
            label:        'form.field.role',
            size:         4,
            remoteSource: this.rolesRepository.active.bind(this.rolesRepository),
            observers:    [
                () => {
                    let selectedRole = this.role_id.instance.selectedRecord();

                    this.entity_id.instance.changeEntityType(selectedRole ? selectedRole.entity_type_id : null);
                    this.entity_id.instance.required(selectedRole ? !!selectedRole.entity_type_id : false);
                },
            ],
        };

        this.entity_id = {
            type:         'entities-select',
            key:          'entity_id',
            label:        'form.field.entity',
            size:         4,
            required:     !!viewModel.model.entity_type_id,
            entityTypeId: viewModel.model.entity_type_id,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.userStatusesRepository.active.bind(this.userStatusesRepository),
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('administration.users.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.name, this.email],
            [this.password, this.password_confirmation],
            [this.role_id, this.entity_id],
            [this.status_id],
            [this.buttons],
        ];
    }

}
